import { colors } from 'AppV2/styles/colors';
import styled, { css, createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`

  :root {

        --lightblue: rgba(72, 134, 217, 1);
        --light-purple: rgba(42, 65, 232, 0.07);
        --light-red: rgba(249, 77, 77, 1);
        --light-gray: rgba(247, 248, 250, 1);
        --lightgray: #F2F1F6;
        --disabledgray: rgba(173, 170, 191, 1);
        --lightorange: #F94D4D;
        --light-background: #FCFCFF;

        --white: rgba(255,255,255, 1);
        --purple: #563CCC;
        --darkpurple: #3A335A;
        --darkblue: #1E1548;
        --transPurple: rgb(86 60 204 / 36%);
        --gray: rgba(134, 146, 166, 1);
        --darkgray: #aaa;
        --finGray: #E0E0E0;
        --transdarkgray: #aaaaaa94;
        --dark: rgba(63, 63, 63, 1);
        --lemmon: rgba(29, 242, 114, 1);
        --green: rgba(8, 141, 37, 1);
        --middleGreen: rgba(35, 201, 101, 1);
        --selectGreen: #219653;
        --yellow: rgba(242, 189, 29, 1);
        --chumbo: #3A335A;

        --box-shadow: 0 1px 4px 0px rgb(0 0 0 / 12%);
        --card-box-shadow-secondary: 0px 10px 14px 1px rgba(0, 0, 0, 0.06);
        --card-box-shadow:  0px 10px 10px rgba(221, 221, 221, 0.25);
        --drag-card-box-shadow: 0px 5px 10px rgba(175, 175, 175, 0.25);
        --shadow-button: 0px 4px 20px rgba(166, 165, 165, 0.25);
        --post-slack-btn-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
        --linear-background: linear-gradient(180deg, #F7F8FA 0%, rgba(247, 248, 250, 0) 100%);
        --linear-background-colorful: linear-gradient(90deg, #F2BD1D 0%, #F94D4D 45.31%, #563CCC 100%), #3A335A;
        --onboarding-first-linear: linear-gradient(133.49deg, #AB668A 14.76%, #CF3030 52.63%, #F2BD1D 85.22%, #F2BD1D 85.22%);
        --linear-blue: linear-gradient(180deg, #4886D9 0%, #2B6CC4 100%);
        --transparent-background: rgba(51, 51, 51, 0.9);

        --gray-background: rgb(106,102,102);
        --gray-linear-background: linear-gradient(180deg, rgba(106,102,102,1) 0%, rgba(147,143,143,1) 73%, rgba(191,188,188,1) 100%);

        #root {
          display: flex;
        }

        p {
            line-height: 21px;
            margin-bottom: 0px;
            color: var(--chumbo);
        }

        #backToTop a {
            background-color: var(--purple)
        }
        .avoid-overflow {
          overflow:hidden
        }
        .flexbox-center {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .flexbox-column-center {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
        #collapsedArrow {
          transform: rotate(-180deg);
        }

        .iti-mobile .intl-tel-input.iti-container {
          z-index: 9999;
        }

        .input-with-label input, textarea {
            background: var(--light-gray);
        }
        .input-with-label label {
            margin: 0px;
        }
        .intl-tel-input .iti-container {
            z-index: 9999 !important;
        }

        .inputs-content {
            position: relative;
        }

        * {
        ::-webkit-scrollbar {
            background-color: ${colors.lightBackground} !important;
            width: 6px !important;
          }
        ::-webkit-scrollbar-thumb {
          background-color: ${colors.purple600} !important;
          border-radius: 9px !important;
        }
          // OLD SCROLL BAR
          /* ::-webkit-scrollbar {
            background-color: 'colors.lightBackground';
            width: 6px;
          }
          ::-webkit-scrollbar-thumb {
            background-color: colors.light;
            border-radius: 9px;
          } */

          /* scrollbar-width: thin;
            scrollbar-color: rgb(72 134 217 / 21%) white; */
        }

        #overflowAuto {
            overflow: auto;
            ::-webkit-scrollbar {
            background-color: white;
            width: .6rem;
            }
            ::-webkit-scrollbar-thumb {
                background-color: rgb(72 134 217 / 21%);
                border-radius: 9px;
            }
        }

        .edit-button-isDesktop {
            display: block;
            div {
                    width: 14rem;
                }
            button {
                width: 100%;
                font-size: 1.5rem;
            }
        }


        .card-education-isDesktop {
            display: block;
                /* div {
                    width: 12rem;
                } */
        }
        .edit-button-isMobile {
            display: none;
            div {
                    width: 12rem;
                }
        }
        .card-education-isMobile {
            display: none;
        }

        input::placeholder {
            font-weight: 600;
            color: #8080807a;
        }

        .error-input-outlined {
            border: 1px solid var(--light-red) !important;
        }
        .error-message-content {
            position: absolute;
            bottom: -7px;
            color: var(--light-red);
            font-size: 1.2rem;
            text-align: left;
        }
        #intl-tel-error-message-content {
            bottom: -22px;
        }

        @media screen and (max-width: 767px) {

            .edit-button-isDesktop {
                display: none;
            }
            .card-education-isDesktop {
                display: none;
            }

            .edit-button-isMobile {
                display: block;

                div {
                    width: 12rem;
                }

                button {
                    width: 100%;
                    font-size: 1.5rem;
                }
            }
            .card-education-isMobile {
                display: block;
            }
        }

        @media screen and (max-width: 540px) {
            input {
                padding: 0px 9px;
            }
            input::placeholder {
                font-size: 1.2rem !important;
            }
        }

        #cubicBezierAnimation, .cubicBezierAnimation {
            animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards !important;
            -webkit-animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards !important;

            @keyframes anvil {
                0% {
                transform: scale(1) translateY(0px);
                opacity: 0;
                box-shadow: 0 0 0 rgba(241, 241, 241, 0);
                }
                1% {
                transform: scale(0.96) translateY(10px);
                opacity: 0;
                box-shadow: 0 0 0 rgba(241, 241, 241, 0);
                }
                100% {
                transform: scale(1) translateY(0px);
                opacity: 1;
                box-shadow: 0 0 500px rgba(241, 241, 241, 0);
                }
            }
        }
      }

      #cubicBezierAnimation-noFill, .cubicBezierAnimation-noFill {
        animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) !important;
        -webkit-animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) !important;

        @keyframes anvil {
            0% {
            transform: scale(1) translateY(0px);
            opacity: 0;
            box-shadow: 0 0 0 rgba(241, 241, 241, 0);
            }
            1% {
            transform: scale(0.96) translateY(10px);
            opacity: 0;
            box-shadow: 0 0 0 rgba(241, 241, 241, 0);
            }
            100% {
            transform: scale(1) translateY(0px);
            opacity: 1;
            box-shadow: 0 0 500px rgba(241, 241, 241, 0);
            }
        }
    }

    .react-modal-overlay {
      background: rgba(0, 0, 0, 0.5);
      position: fixed;
      z-index: 9999;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .react-modal-content {
      width: 100%;
      max-width: 860px;
      height: 90%;
      background: var(--white);
      padding: 0 3rem;
      position: relative;
      border-radius: 2rem;
      overflow: auto;
      overflow-x: hidden;
    }

    .react-modal-confirmation-content {
      width: 100%;
      max-width: 700px;
      height: 370px;
      background: var(--white);
      padding: 0 1rem;
      position: relative;
      border-radius: 2rem;
      overflow: auto;
      overflow-x: hidden;
    }

    .react-modal-close {
      position: absolute;
      z-index: 9990000;
      right: 1.5rem;
      top: 1.5rem;
      border: 0;
      background: transparent;
      transition: filter 0.2s;

      &:hover {
        filter: brightness(0.8);
      }
    }

`;

export const MainContainer = styled.main`
  width: 100%;
  min-height: 100vh;
  background-color: ${colors.background100};

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalMainContainer = styled(MainContainer)`
  max-width: none;
  width: 100vw;
  background-color: var(--transparent-background);
  position: fixed;
  z-index: 9999;

  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  animation: shadow 0.6s ease-in-out;

  @keyframes shadow {
    0% {
      background-color: rgb(51 51 51 / 0%);
    }
    100% {
      background-color: var(--transparent-background);
    }
  }
`;

export const SectionContainer = styled.section`
  width: 100%;
  max-width: 540px;
  text-align: center;
`;

export const CleanHeaderContainer = styled.header`
  width: 100%;
  height: 9rem;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: var(--light-gray);

  .logo-content {
    width: 210px;
  }

  @media screen and (max-width: 550px) {
    height: 4.5rem;

    .logo-content {
      width: 145px;
    }
  }

  @media screen and (min-width: 551px) and (max-width: 779px) {
    height: 7.2rem;

    .logo-content {
      width: 180px;
    }
  }
`;

export const RedirectLink = styled.a`
  width: 100%;
  height: 48px;
  display: flex;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  background: var(--purple);
  color: var(--white) !important;
  font-size: 1.5rem;
  font-weight: 600;
  transition: 0.3s;
  padding-left: 9px;
  cursor: pointer;
  .fas {
    font-size: 1.5rem;
    opacity: 0;
    transition: 0.3s;
    padding-left: 0px;
  }
  :hover {
    padding-left: 0px;
    color: white;
    .fas {
      opacity: 1;
      color: white;
      padding-left: 9px;
    }
  }
  :visited {
    color: white;
  }
  ~ [aria-disabled='true'] {
    cursor: not-allowed !important;
    filter: grayscale(0.8) !important;
    opacity: 0.8;
    :hover {
      padding-left: 9px;
      .fas {
        opacity: 0;
        padding-left: 0px;
      }
    }
  }
`;

export const ButtonContainer = styled.div`
  button {
    width: 100%;
    height: 48px;

    color: white;
    background-color: #563ccc;
    font-weight: 700;
    font-size: 1.4rem;
    border-radius: 4px;

    animation: descAnvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards !important;
    -webkit-animation: descAnvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9)
      forwards !important;

    @keyframes descAnvil {
      0% {
        transform: scale(1) translateY(0px);
        opacity: 0;
      }
      1% {
        transform: scale(0.96) translateY(10px);
        opacity: 0;
      }
      50% {
        transform: scale(1) translateY(0px);
        opacity: 0.81;
      }
      100% {
        transform: scale(1) translateY(0px);
        opacity: 1;
      }
    }
  }
  /* @media screen and (max-width: 480px) {

        width: 50%;
        height: 39px;
        button {
            font-size: 1.3rem;
            margin: 0px !important;
            padding: 0px !important;
            padding-left: 10px !important;
            height: 100%;
        }

    } */

  ${props =>
    props.projectBriefSelection &&
    css`
      #btnBackPerfil {
        text-align: left;
        color: #4886d9 !important;
        background: transparent !important;
        box-shadow: none;
        width: 100%;
        height: 100%;
      }

      .button.button-sliding-icon {
        padding-left: 0px;
        padding-right: 11px;
      }

      @media screen and (max-width: 997px) {
        width: 100%;
        margin-bottom: 1.2rem;
      }
    `}

  ${props =>
    props.submit &&
    css`
      width: 45%;
    `}

    ${props =>
    props.emailCheckedContent &&
    css`
      button {
        width: 180px !important;
        margin: 0 auto;
      }
    `}

    ${props =>
    props.logoutContent &&
    css`
      margin-top: 5.4rem;

      button {
        width: 180px !important;
        margin: 0 auto;
        background-color: #eaeaea !important;
        color: #7a7a7a !important;
      }
    `}

    ${props =>
    props.profileRegister &&
    css`
      width: 100%;
      margin-top: 3rem;

      button {
        width: 100%;
      }

      @media screen and (min-height: 800px) {
        margin-top: 6rem;
      }
    `}

    ${props =>
    props.modalWelcome &&
    css`
      button {
        background-color: rgba(72, 134, 217, 1);
        box-shadow: none;
      }
      #second-button {
        background-color: white;
        border: 1px solid rgba(72, 134, 217, 1);
        color: rgba(72, 134, 217, 1);
        box-shadow: none;
      }
    `}

    ${props =>
    props.modalPictureTips &&
    css`
      width: 50%;
      margin: 0 auto;

      button {
        background-color: white !important;
        border: 1px solid rgba(63, 63, 63, 1);
        color: rgba(63, 63, 63, 1);
        box-shadow: none;
      }

      @media screen and (max-width: 480px) {
        width: 50%;
        height: 39px;
        button {
          font-size: 1.2rem;
          margin: 0px !important;
          padding: 0px !important;
          padding-left: 10px !important;
          height: 100%;
        }
      }
    `}

    ${props =>
    props.modalCropPicture &&
    css`
      width: 45%;
    `}

    ${props =>
    props.modalCropBackButton &&
    css`
      width: 45%;
      margin-right: 0rem;
      margin-left: 0rem;

      #backBtn {
        width: 100%;
        background: white;
        border: 1px solid rgba(63, 63, 63, 1);
        color: rgba(63, 63, 63, 1);
        box-shadow: none;
      }
      .button.button-sliding-icon {
        padding-left: 0px;
        padding-right: 10px;
      }

      #backIcon {
        -moz-transform: scaleX(-1);
        -o-transform: scaleX(-1);
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
      }

      @media screen (max-width: 540px) {
        #backBtn {
          margin: 0px !important;
          padding: 0px !important;
          padding-left: 10px !important;
        }
      }
    `}

    ${props =>
    props.fillProfilePicture &&
    css`
      width: 42%;
      margin-right: 1rem;
      margin-left: 1rem;

      @media screen and (max-width: 879px) {
        width: 100%;
        max-width: 390px;
      }
    `}

    ${props =>
    props.fillProfile &&
    css`
      width: 42%;
      margin-right: 1rem;
      margin-left: 1rem;

      @media screen and (max-width: 879px) {
        /* width: 100%; */
      }
    `}

    ${props =>
    props.backButton &&
    css`
      width: 42%;
      margin-right: ${props.marginRight || '3rem'};
      margin-left: ${props.marginLeft || '1rem'};

      #backBtn {
        width: 100%;
        background: white;
        border: 1px solid rgba(63, 63, 63, 1);
        color: rgba(63, 63, 63, 1);
        box-shadow: none;
      }
      .button.button-sliding-icon {
        padding-left: 0px;
        padding-right: 0px;
      }

      #backIcon {
        -moz-transform: scaleX(-1);
        -o-transform: scaleX(-1);
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
      }

      @media screen (max-width: 540px) {
        #backBtn {
          margin: 0px !important;
          padding: 0px !important;
          padding-left: 10px !important;
        }
      }
    `}

    ${props =>
    props.briefBackButton &&
    css`
      width: 100%;
      margin-right: 0rem;
      margin-left: 0rem;

      #backBtn {
        width: 100%;
        background: white;
        /* border: 1px solid rgba(63, 63, 63, 1); */
        color: var(--purple);
        box-shadow: none;
      }
      .button.button-sliding-icon {
        padding-left: 0px;
        padding-right: 0px;
      }

      #backIcon {
        -moz-transform: scaleX(-1);
        -o-transform: scaleX(-1);
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
      }

      @media screen (max-width: 540px) {
        #backBtn {
          margin: 0px !important;
          padding: 0px !important;
          padding-left: 10px !important;
        }
      }
    `}

    ${props =>
    props.modalBackButton &&
    css`
      width: 231px;
      margin-right: 3rem;
      margin-left: 1rem;

      #backBtn {
        width: 100%;
        background: white;
        border: 1px solid rgba(63, 63, 63, 1);
        color: rgba(63, 63, 63, 1);
        box-shadow: none;
      }
      .button.button-sliding-icon {
        padding-left: 0px;
        padding-right: 0px;
      }

      #backIcon {
        -moz-transform: scaleX(-1);
        -o-transform: scaleX(-1);
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
      }
    `}

    ${props =>
    props.saveInfos &&
    css`
      width: 100%;
      display: flex;
      justify-content: flex-end;

      button {
        background: white !important;
        border: 1px solid var(--lightblue) !important;
        color: var(--lightblue) !important;
        box-shadow: none !important;
      }

      i {
        margin-left: 1rem;
      }

      #btn-save-infos-education-content {
        /* margin-bottom: 12rem; */
      }

      @media screen and (min-height: 930px) {
        margin: 1.8rem 0px;
      }
      @media screen and (max-width: 879px) {
        margin: 0px;
      }
    `}

    ${props =>
    props.moreDetals &&
    css`
      width: 100%;
      height: 36px;
      margin: 0px;
      display: flex;
      justify-content: flex-end;

      button {
        width: 27%;
        height: 100%;
        background: none;
        box-shadow: none;
        color: var(--purple);
        font-weight: bold;
        text-align: right !important;
        margin: 0px !important;
        padding: 0px !important;
      }

      i {
        margin-left: 1rem;
      }
    `}

    ${props =>
    props.testsContent &&
    css`
      width: 90%;
      margin: 0 auto;

      #results-button {
        background-color: var(--yellow) !important;
        color: var(--dark) !important;
      }
    `}

    ${props =>
    props.resetPassword &&
    css`
      margin-top: 3.6rem;

      button {
        width: 100%;
        height: 48px;
        border-radius: 4px;

        color: white;
        font-weight: 700;
        background-color: var(--purple);
      }
    `}

    ${props =>
    props.editProfile &&
    css`
      width: 14rem;
      margin-left: auto;

      button {
        width: 100% !important;
        margin-top: 0px !important;
        line-height: 0px;
        height: 3rem;
        padding: 0px;
        padding-left: 0px !important;
        padding-right: 10px !important;

        background: var(--purple);
        color: var(--white);
        font-weight: 600;
        font-size: 1.5rem;
        border-radius: 4px;

        box-shadow: none;

        i {
          margin-right: 0.1rem;
          padding-left: 0px !important;
        }
      }

      #addInfo {
        background: var(--purple);
        padding-right: 11px !important;
        padding-left: 0px !important;
        color: white;
        i {
          margin-left: 0.5rem;
          margin-right: 0.1rem;
        }
      }

      @media screen and (max-width: 767px) {
        width: 12rem;
        #addInfo {
          width: 12rem;
        }
      }
    `}

    ${props =>
    props.Page404 &&
    css`
      width: 48%;
      button {
        height: auto;
      }

      #btnContact {
        background: var(--middleGreen);
        box-shadow: none;
        width: 100%;
        height: 100%;
        padding-left: 9px;
        padding-right: 0px;
      }

      .button.button-sliding-icon {
        padding-left: 0px;
        padding-right: 11px;
      }

      #backIcon {
        -moz-transform: scaleX(-1);
        -o-transform: scaleX(-1);
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
      }

      @media screen and (max-width: 997px) {
        width: 100%;
        margin-bottom: 1.2rem;
      }
    `}

    ${props =>
    props.leftIcon &&
    css`
      button {
        width: 100%;
        height: 48px;
        color: white;
        border-radius: 4px;
        margin-bottom: 1.8rem;
        font-size: 1.4rem;
        font-weight: 600;
        background: ${props.bgColor};
        color: ${props.fontColor};
        border: ${props.borderStyle};

        span {
          position: relative;
          transition: 0.3s;
          display: inline-block;
          i {
            position: absolute;
            transition: 0.1s ease-in;
            left: 0px;
            opacity: 0;
            margin-right: 0.3px;
            /* display: inline-block; */
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          #materialDefaultIcon {
            font-size: 2rem !important;
            -ms-transform: rotate(180deg);
            transform: rotate(180deg);
          }
        }
        :hover {
          span {
            transform: translateX(15%);
            i {
              transform: translateX(-27px);
              opacity: 1;
            }
            #materialDefaultIcon {
              -ms-transform: rotate(180deg) translateX(33px);
              transform: rotate(180deg) translateX(33px);
              opacity: 1;
            }
          }
        }
      }

      @media screen and (max-width: 480px) {
        button {
          font-size: 1.4rem;
        }
      }
    `}

    ${props =>
    props.addInfo &&
    css`
      background-color: transparent;
      width: 100%;

      button {
        background-color: transparent;
        font-size: 1.4rem;
        font-weight: 600;
        color: var(--purple);
        transition: 0.3s;
        padding-right: 2rem;
        text-align: ${props.align};
        ::before {
          content: '\f067';
          font-family: 'Font Awesome 5 Free';
          font-size: 1.4rem;
          color: var(--purple);
          transition: 0.3s;
          opacity: 0;
          margin-right: 0.9rem;
        }
        :hover {
          padding-right: 0rem;
          ::before {
            opacity: 1;
          }
        }
      }
    `}

    ${props =>
    props.transparent &&
    css`
      background: transparent;
      color: ${props.thisColor} || var(--lightblue);

      button {
        background: transparent;
      }
    `}

    ${props =>
    props.setColor &&
    css`
      opacity: ${props.opacity || '1'};

      button {
        background: ${props.bg || 'var(--purple)'} !important;
        color: ${props.thisColor || 'var(--white)'} !important;
        border: ${props.border || 'none'} !important;
        width: ${props.width || '100%'} !important;
        margin: 0 auto !important;
        cursor: ${props.cursor || 'pointer'} !important;
        i {
          margin-left: 9px !important;
        }
      }
    `}
`;

export const CheckBoxContainer = styled.div`
  width: 60px;
  height: 12px;
  display: flex;
  font-size: 1.2rem;
  margin: 0.6rem 0rem;

  position: relative;
  cursor: pointer;

  input {
    opacity: 0;
    position: absolute;
    cursor: pointer;
    height: 0;
    width: 0;
    margin: 0px;
  }

  .checkmark {
    position: absolute;
    height: 100%;
    width: 12px;
    left: 0;
    border-radius: 2px;
    border: 0.3px solid var(--darkgray);
    background-color: var(--light-gray);

    display: flex;
    align-items: center;
    justify-content: center;

    i {
      color: var(--purple);
      display: none;
    }
  }

  #checked-mark {
    border: none;

    i {
      display: block;
    }
  }

  .value {
    position: absolute;
    left: 15px;
    height: 100%;
    top: -6.3px;
  }

  ${props =>
    props.isWhitOutLabel &&
    css`
      width: 0px;
      padding-right: 0px;
      height: 18px;

      .checkmark {
        font-size: 2.1rem;
        width: 18px;
      }
    `}

  ${props =>
    props.bigLabel &&
    css`
      width: 100% !important;
      height: 18px;
      margin: 3rem 0rem;
      .checkmark {
        width: 18px;
      }
      #checked-mark {
        width: 18px;
        height: 18px;
        font-size: 1.8rem;
      }
      .value {
        font-size: 1.5rem;
        left: 27px;
        line-height: initial;
      }
    `}
`;

export const SpinnerContainer = styled.div.attrs({
  className: 'flexbox-center',
})`
  width: 100%;
  height: ${props => props.thisHeight};
  margin: 0 auto;
  text-align: center;
  line-height: initial;
  display: grid;
  place-items: center;

  #spinner {
    font-size: 2.1rem;
    color: var(--purple);
  }

  ${props =>
    props.thisMargin &&
    css`
      margin: ${props.thisMargin};
      /* height: 45px; */
    `}
`;

export const SelectContainer = styled.div`
  position: relative;

  .handmade-select-content {
    position: relative;
    input {
      text-transform: capitalize;
      cursor: pointer;
      background: ${({ bgColor }) => bgColor || `var(--light-gray)`};
    }
    i {
      position: absolute;
      top: 0px !important;
      right: 0px !important;
      font-size: 1.6rem;
      /* right: 18px;
      top: 12px; */
      width: 100% !important;
      height: 100% !important;
      text-align: end;
      padding-right: 17px !important;
      padding-top: 13px !important;
      cursor: pointer;
    }
  }
  .select-options-content {
    z-index: 99999 !important;
    max-height: 15rem;
    position: absolute;
    top: 0px !important;
    border-radius: 4px;
    box-shadow: 1px 2px 9px 3px #0000002b;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    background: white;
    padding: 0.3rem;
    ::-webkit-scrollbar {
      background-color: var(--light-gray);
      width: 0.6rem;
    }
    ::-webkit-scrollbar-thumb {
      background-color: var(--transdarkgray);
      border-radius: 9px;
    }
    #gender-input {
      cursor: pointer;
    }
    span {
      font-size: 1.45rem;
      font-weight: 600;
      width: 100%;
      color: var(--darkgray);
      transition: 0.27s;
      padding-left: 1.7rem;
      cursor: pointer;
    }
    // span:hover {
    //     background: var(--light-gray);
    //     color: var(--gray);
    // }
  }
  .mouseSelect:hover {
    background: var(--light-gray);
    color: var(--gray);
  }
  .arrowSelect {
    background: var(--light-gray);
    color: var(--gray) !important;
  }
`;
export const SelectDisabledContainer = styled.div`
  position: relative;
  text-transform: capitalize;

  .fas {
    position: absolute;
    right: 17px;
    top: 16px;
    content: '\f2ed';
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--purple);
    cursor: pointer;
    transition: 0.3s;
    :hover {
      opacity: 0.6;
    }
  }
`;

export const LightGrayBoxContainer = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 630px;
  padding: 3rem;
  background: var(--light-gray);
  border-radius: 9px;
  box-shadow: var(--box-shadow);

  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 480px) {
    padding: 1.5rem;
  }
`;
