/* eslint-disable react/display-name */
/* eslint-disable react/jsx-filename-extension */
import { ReactElement } from 'react';
import * as S from '../styles';
import Lottie from 'lottie-react';
import loadingPageGrowyxLottie from 'AppV2/assets/lotties/Loading-Growyx.json';
import { CSSObject } from 'styled-components';

interface Props {
  styles?: CSSObject;
}

export const LoadingPageDefault = ({ ...props }: Props): ReactElement => {
  return (
    <S.LoadingPageContainer styles={props.styles}>
      <S.MotionDivWrapper {...S.MotionAnimation}>
        <Lottie
          loop={true}
          autoPlay={true}
          animationData={loadingPageGrowyxLottie}
        />
      </S.MotionDivWrapper>
    </S.LoadingPageContainer>
  );
};
