/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/display-name */
/* eslint-disable react/jsx-filename-extension */
import React, { memo, useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useCookies } from 'react-cookie';
import { useAuth } from 'Contexts/AuthContext';
import { useModal } from 'AppV2/providers/ModalProvider';
import { HeaderWrapper, UserSection } from './styles';
import { Logo } from 'AppV2/components/Logo';
import { AppLanguageModalKey, UserMenu, WorkspaceModalKey } from './UserMenu';
import { ModalSelectWorkspace } from './UserMenu/ModalSelectWorkspace';
import { UserRoleEnum, UserRoleType } from 'AppV2/interfaces/userRole';
import {
  WorkspaceIdCookieKey,
  workspaceServices,
  WorkspaceUserNameCookieKey,
  WorkspaceUserPhotoCookieKey,
} from 'AppV2/services/Workspace';
import { GetInvitedWorkspacesQueryCacheKey } from 'AppV2/services/Workspace/GetInvitedWorkspaces/types';
import { translateText } from 'AppV2/common/translateText';
import { currentUserWorkspaceObject, formatInvitedWorkspaces } from './helpers';
import { ModalSelectLanguage } from './UserMenu/ModalSelectAppLanguage';
import { SelectWorkspaceParams, useWorkspace } from 'AppV2/hooks/Workspace';
import { Notification } from '../Notification';
import { URL_PATHS_WITHOUT_MOBILE } from 'AppV2/assets/allUserRoleRoutes';
import { useLocation } from 'react-router-dom';
import { useScreenDetect } from 'AppV2/hooks/ScreenDetect';
import { ModalNoMobileOptimization } from '../ModalNoMobileOptimization';
import { apiV2Instance } from 'AppV2/configs/Axios/api_V2';
import { LoadingPage } from 'AppV2/components/LoadingPage';

export interface WorkspacesProps {
  isOwner: boolean;
  workspaceId: string;
  userName: string;
  userPhoto: string;
}

interface Props {
  headersWorkspaceId?: string;
  userRole?: UserRoleType;
  shouldShowLogo?: boolean;
  isAnotherWorkspaceSelected?: boolean;
}

export const Header = memo(
  ({
    userRole,
    isAnotherWorkspaceSelected,
    headersWorkspaceId,
    shouldShowLogo,
  }: Props): React.ReactElement => {
    // HOOKS
    const { setSessionStorageWorkspaceSelectedForbiddens, selectWorkspace } =
      useWorkspace();
    const { currentUser, getCurrentUserPhoto, setWorkspaceSelectedForbiddens } =
      useAuth();
    const location = useLocation();
    const { isThisWidthDetected } = useScreenDetect(768);
    const { openModal, setOpenAlert } = useModal();
    const currentUserUid = currentUser?.uid;

    // COOKIES
    const [cookie] = useCookies([
      `${WorkspaceIdCookieKey}${currentUserUid}`,
      `${WorkspaceUserNameCookieKey}${currentUserUid}`,
      `${WorkspaceUserPhotoCookieKey}${currentUserUid}`,
    ]);

    // STATES - VALUES
    const shouldShowNotification =
      isThisWidthDetected &&
      URL_PATHS_WITHOUT_MOBILE.some(path =>
        String(location?.pathname).includes(path),
      );
    const isSquadOwner = userRole === UserRoleEnum.squadOwner;
    const isMatchmakingTrial = !!currentUser?.claims?.matchmakingTrial;
    const [user, setUser] = useState(() => ({
      displayName:
        cookie[`${WorkspaceUserNameCookieKey}${currentUserUid}`] ||
        currentUser?.displayName,
      photoUrl:
        cookie[`${WorkspaceUserPhotoCookieKey}${currentUserUid}`] ||
        getCurrentUserPhoto(),
    }));
    const [loadingWorkspaceOwner, setLoadingWorkspaceOwner] = useState(
      isSquadOwner && !isMatchmakingTrial,
    );
    const [showLogo, setShowLogo] = useState(false);
    const [workspaces, setWorkspaces] = useState<WorkspacesProps[]>([]);
    const [openNoMobileOptimizationModal, setOpenNoMobileOptimizationModal] =
      useState(false);

    // FUNCTIONS
    const handleOpenNoMobileOptimizationModal = useCallback(
      () => setOpenNoMobileOptimizationModal(state => !state),
      [],
    );

    const handleSelectWorkspace = useCallback(
      (params: SelectWorkspaceParams) => {
        selectWorkspace({
          workspaceId: params.workspaceId,
          owner: params.owner,
        });

        setUser({
          displayName: params.owner.name,
          photoUrl: params.owner.photoURL,
        });
      },
      [],
    );

    // HTTP FUNCTIONS
    const getInvitedWorkspaces = useCallback(async () => {
      if (isSquadOwner && !isMatchmakingTrial) {
        const response = await workspaceServices.getInvitedWorkspaces();
        return response?.data;
      }

      throw Error;
    }, []);

    // CACHE DATA
    const { isFetched, isFetching, data, error } = useQuery(
      GetInvitedWorkspacesQueryCacheKey,
      getInvitedWorkspaces,
    );

    // EFFECTS
    useEffect(() => {
      setShowLogo(shouldShowLogo);
    }, [shouldShowLogo]);

    useEffect(() => {
      if (!openNoMobileOptimizationModal && shouldShowNotification) {
        setOpenNoMobileOptimizationModal(true);
      }
    }, [shouldShowNotification]);

    useEffect(() => {
      if (isSquadOwner && !isMatchmakingTrial) {
        if (error) {
          setOpenAlert({
            type: 'error',
            shouldOpen: true,
            title: translateText('HEADER:ALERT_GET_INVITEDS_ERROR_TITLE'),
            description: `${translateText(
              'HEADER:ALERT_GET_INVITEDS_ERROR_DESCRIPTION',
            )} ${String(error)}`,
          });
          return;
        }
        if (isFetched) {
          const invitedWorkspaces = data?.invitedRepository || [];
          const formattedInvitedWorkspaces =
            formatInvitedWorkspaces(invitedWorkspaces);

          setWorkspaces([
            currentUserWorkspaceObject(currentUser),
            ...formattedInvitedWorkspaces,
          ]);

          const workspaceSelected = invitedWorkspaces.find(
            workspace =>
              workspace.workspaceId ===
              cookie[`${WorkspaceIdCookieKey}${currentUserUid}`],
          );
          const hasWorkspaceIdCookieInInvitedsList = !!workspaceSelected;

          if (hasWorkspaceIdCookieInInvitedsList) {
            const workspaceForbiddens =
              setSessionStorageWorkspaceSelectedForbiddens(workspaceSelected);

            setWorkspaceSelectedForbiddens(workspaceForbiddens);
          }
        }
      }
    }, [data, isFetched, error, setOpenAlert, currentUserUid]);

    useEffect(() => {
      if (isSquadOwner && workspaces?.length) {
        setTimeout(() => {
          const responseHeaderWorkspaceId = sessionStorage.getItem(
            'RESPONSE_HEADER_WORKSPACE_ID',
          );
          const storageWorkspaceInvite =
            localStorage.getItem('WORKSPACE_INVITE');

          if (responseHeaderWorkspaceId || storageWorkspaceInvite) {
            const currentWorkspaceId =
              apiV2Instance.defaults.headers.workspaceId;

            const currentWorkspaceOwner = workspaces.find(
              workspace => workspace.workspaceId === currentWorkspaceId,
            );

            const storageWorkspaceId =
              responseHeaderWorkspaceId ||
              JSON.parse(storageWorkspaceInvite || '{}')?.workspaceId ||
              currentWorkspaceId;

            if (storageWorkspaceId !== currentWorkspaceOwner?.workspaceId) {
              const workspaceOwner = workspaces.find(
                workspace => workspace.workspaceId === storageWorkspaceId,
              );

              if (workspaceOwner) {
                selectWorkspace({
                  workspaceId: storageWorkspaceId,
                  owner: {
                    name: workspaceOwner.userName,
                    photoURL: workspaceOwner.userPhoto,
                  },
                  onSuccess: () => {
                    sessionStorage.removeItem('RESPONSE_HEADER_WORKSPACE_ID');
                    localStorage.removeItem('WORKSPACE_INVITE');
                    setUser({
                      displayName: workspaceOwner.userName,
                      photoUrl: workspaceOwner.userPhoto,
                    });
                  },
                });
              }
            }
          }

          setLoadingWorkspaceOwner(false);
        }, 900);
      }
    }, [workspaces]);

    if (loadingWorkspaceOwner) {
      return <LoadingPage styles={{ zIndex: 99999 }} />;
    }

    return (
      <>
        {/* ::: MODALS ::: */}
        <ModalSelectWorkspace
          isOpen={openModal?.key === WorkspaceModalKey}
          headersWorkspaceId={headersWorkspaceId}
          isLoading={isFetching}
          workspaces={workspaces}
          onSelectWorkspace={handleSelectWorkspace}
        />

        <ModalSelectLanguage isOpen={openModal?.key === AppLanguageModalKey} />

        <ModalNoMobileOptimization
          isOpen={openNoMobileOptimizationModal}
          onClose={handleOpenNoMobileOptimizationModal}
        />
        {/* ::: MODALS ::: */}

        <HeaderWrapper
          className="cubicBezierAnimation"
          isWorkspaceSelected={isAnotherWorkspaceSelected}
        >
          {shouldShowNotification && <Notification />}

          <div className="inner-wrapper">
            {showLogo && (
              <Logo isWorkspaceSelected={isAnotherWorkspaceSelected} />
            )}

            <UserSection>
              <UserMenu
                userRole={userRole}
                userName={user.displayName}
                userPhoto={user.photoUrl}
                isAnotherWorkspaceSelected={isAnotherWorkspaceSelected}
              />
            </UserSection>
          </div>
        </HeaderWrapper>
      </>
    );
  },
);
