/* eslint-disable react/display-name */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
import { memo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import setLoginOrSignUpFormThunk from 'Store/modules/loginSignUpForms/changeForm/thunk';
import Login from 'Components/Organisms/Login';
import SignUp from 'Components/Organisms/SignUp';
import ButtonsChangeForms from 'Components/Molecules/LoginSignUp/ButtonsChangeForms';
import Logo from 'Components/Atoms/Generals/Logo';
import {
  LoginSignupMainContainer,
  LoginSignupSectionContainer,
} from 'Containers/LoginSignUp';
import { useAuth } from 'Contexts/AuthContext';
import { useStorageRedirects } from 'AppV2/hooks/StorageRedirects';

const LoginAndSignUp = () => {
  // HOOKS
  const dispatch = useDispatch();
  const { isLoginForm } = useSelector(state => state);
  const { isFirstSocialLogin } = useAuth();
  const { getStorageUrl } = useStorageRedirects();

  // VALUES
  const isRedirectFromSelfAdmission = !!localStorage.getItem(
    'isLogOffUserSelfAdmission',
  );

  const isRedirectFromMatchmakingAcceptInvite = !!getStorageUrl(
    'MATCHMAKING_ACCEPT_INVITE',
  );

  const isRedirectFromAssessmentsAcceptInvite = !!localStorage.getItem(
    'ASSESSMENTS_INVITE_DATA',
  );

  const isRedirectFromWorkspaceInvite =
    !!localStorage.getItem('WORKSPACE_INVITE');

  const shouldShowAccountsType =
    !isRedirectFromSelfAdmission &&
    !isRedirectFromMatchmakingAcceptInvite &&
    !isRedirectFromWorkspaceInvite &&
    !isRedirectFromAssessmentsAcceptInvite;

  // EFFECTS
  useEffect(() => {
    if (isFirstSocialLogin && isLoginForm) {
      dispatch(setLoginOrSignUpFormThunk(false));
    }
    if (location.pathname === '/login' && !isLoginForm) {
      dispatch(setLoginOrSignUpFormThunk(true));
      return;
    }
    if (location.pathname === '/signup' && isLoginForm) {
      dispatch(setLoginOrSignUpFormThunk(false));
    }
  }, [dispatch, isFirstSocialLogin, isLoginForm]);

  return (
    <LoginSignupMainContainer>
      <Logo />
      <LoginSignupSectionContainer>
        <ButtonsChangeForms showsSignInLoginOptions={!isFirstSocialLogin} />
        {isLoginForm ? (
          <Login />
        ) : (
          <SignUp showsAccountType={shouldShowAccountsType} />
        )}
      </LoginSignupSectionContainer>
    </LoginSignupMainContainer>
  );
};

export default memo(() => <LoginAndSignUp />);
