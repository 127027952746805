/* eslint-disable react/jsx-filename-extension */
import { ReactElement, Suspense, useLayoutEffect } from 'react';
import * as S from '../styles';
import Lottie from 'lottie-react';
import loadingPageGrowyxLottie from 'AppV2/assets/lotties/Loading-Growyx.json';
import { LazyLoadingPage } from './Lazy';

export const LoadingBasic = (): ReactElement => {
  useLayoutEffect(() => {
    const rootDiv = document.getElementById('root');
    if (rootDiv) {
      rootDiv.style.overflow = 'hidden';
    }
  }, []);

  return (
    <Suspense fallback={<LazyLoadingPage />}>
      <S.BasicMotionDivWrapper {...S.MotionAnimation}>
        <Lottie
          loop={true}
          autoPlay={true}
          animationData={loadingPageGrowyxLottie}
          className="lottie-player"
        />
      </S.BasicMotionDivWrapper>
    </Suspense>
  );
};
