/* eslint-disable react/display-name */
import { ReactElement } from 'react';
import * as S from '../styles';
import { CSSObject } from 'styled-components';

interface Props {
  styles?: CSSObject;
}

export const LazyLoadingPage = ({ ...props }: Props): ReactElement => {
  return (
    <S.LazyPageContainer styles={props.styles}>
      <S.BulletsWrapper {...S.MotionAnimation}>
        <S.BulletLoader bulletIndex={0} />
        <S.BulletLoader bulletIndex={1} />
        <S.BulletLoader bulletIndex={2} />
      </S.BulletsWrapper>
    </S.LazyPageContainer>
  );
};
