/* eslint-disable react/jsx-filename-extension */
import { ButtonHTMLAttributes, ReactElement, ReactNode, useState } from 'react';
import {
  OptionLink,
  OptionsAnimationProps,
  OptionsButton,
  OptionsPopupContainer,
} from './styles';
import { CSSObject } from 'styled-components';
import { AnimatePresence } from 'framer-motion/dist/framer-motion';
import { Spinner } from '../Spinner';
import { useScreenDetect } from 'AppV2/hooks/ScreenDetect';

export interface DropDownLinks {
  color?: 'regular' | 'warning';
  text: string;
  icon?: ReactNode;
  iconSize?: string;
  textFontSize?: string;
  action: () => void;
}

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  isActive?: boolean;
  closeOnAction?: boolean;
  animationOnAction?: boolean;
  dropDownLinks?: DropDownLinks[];
  loading?: boolean;
  styles?: CSSObject;
}

export const Options = ({
  isActive,
  dropDownLinks,
  styles,
  closeOnAction = true,
  animationOnAction,
  loading,
  ...rest
}: Props): ReactElement => {
  const { isMobile } = useScreenDetect();
  const [openPopUp, setOpenPopUp] = useState(false);
  const handleOpenOrClosePopUp = () => setOpenPopUp(!openPopUp);
  const LINKS: DropDownLinks[] = dropDownLinks || [];

  return (
    <OptionsButton
      isActive={isActive}
      isDropDownOpen={openPopUp}
      onClick={() => {
        if (isMobile) {
          handleOpenOrClosePopUp();
        } else if (!openPopUp) {
          handleOpenOrClosePopUp();
        } else if (closeOnAction) {
          handleOpenOrClosePopUp();
        }
      }}
      styles={styles}
      loading={loading ? 'true' : 'false'}
      {...rest}
    >
      {loading ? (
        <div className="loading-wrapper">
          <Spinner />
        </div>
      ) : (
        <span className="option-bullet">
          <div className="hover-content" />
        </span>
      )}

      <AnimatePresence>
        {openPopUp && (
          <OptionsPopupContainer
            onMouseLeave={handleOpenOrClosePopUp}
            {...OptionsAnimationProps}
          >
            {LINKS.map(link => (
              <OptionLink
                key={link?.text}
                iconSize={link?.iconSize}
                textFontSize={link?.textFontSize}
                color={link?.color}
                hasDivider={dropDownLinks?.length > 1}
                animationOnAction={animationOnAction || undefined}
                onClick={link?.action}
              >
                {link.icon && <div className="icon-content">{link.icon}</div>}
                <span className="link-text-content">{link.text}</span>
              </OptionLink>
            ))}
          </OptionsPopupContainer>
        )}
      </AnimatePresence>
    </OptionsButton>
  );
};
