/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
import LottieAnimation from 'Components/Atoms/Generals/LottieAnimation';
import { LoadingContainer } from 'Containers/Loading';
import loadingPageGrowyxLottie from 'AppV2/assets/lotties/Loading-Growyx.json';

const Loading = ({
  isSmall = false,
  // thisSrc = 'https://assets10.lottiefiles.com/packages/lf20_jaejabqz.json',
  thisSrc = loadingPageGrowyxLottie,
  thisWidth = '100%',
}) => {
  return isSmall ? (
    <LottieAnimation thisSrc={thisSrc} thisWidth={thisWidth} />
  ) : (
    <LoadingContainer data-testid="loading-component">
      <LottieAnimation thisSrc={thisSrc} thisWidth="320px" />
    </LoadingContainer>
  );
};

export default Loading;
