/* eslint-disable react/jsx-filename-extension */
import { Fragment, ReactElement, useEffect } from 'react';
import Routes from 'Routes';
import theme from 'styles/theme';
import { ThemeProvider } from 'styled-components';
import { APPV2GlobalStyles } from 'AppV2/styles/global';
import { GlobalStyles } from 'Containers';
import { useCookies } from 'react-cookie';
import { WorkspaceIdCookieKey } from 'AppV2/services/Workspace';
import { useAuth } from 'Contexts/AuthContext';
import { useWorkspace } from 'AppV2/hooks/Workspace';
import { useAppLanguage } from 'AppV2/hooks/AppLanguage';
import { AppLanguageCookieKey } from 'AppV2/hooks/AppLanguage/types';
import { Header } from 'AppV2/templates/components/Header';
import { MenuNavBar } from 'AppV2/templates/components/MenuNavBar';
import { MainTemplateLoadingWrapper } from 'AppV2/templates/Main/style';
import { LoadingPage } from 'AppV2/components/LoadingPage';
import { useScreenDetect } from 'AppV2/hooks/ScreenDetect';
import { useProfessionsGroup } from 'AppV2/hooks/ProfessionsGroup';
import { UserRoleEnum } from 'AppV2/interfaces/userRole';
import useGaTracker from 'Analytics/useGaTracker';
import useGoogleTagManager from 'Analytics/useGoogleTagManager';

const App = (): ReactElement => {
  // HOOKS
  const {
    shouldShowHeader,
    shouldShowNavBar,
    defaultAxiosHeaders,
    isAppV2Paths,
    currentUser,
  } = useAuth();
  const { isFetching } = useProfessionsGroup();
  const { isMobile } = useScreenDetect();
  const { changeAppLanguage } = useAppLanguage();
  const { isAnotherWorkspaceSelected } = useWorkspace();

  // STATES
  const keyToForceRerender = `growyxApp_isMobile:${isMobile}`;
  const [cookie] = useCookies([AppLanguageCookieKey, WorkspaceIdCookieKey]);
  const isCurrentUserInAnotherWorkspace =
    isAnotherWorkspaceSelected(currentUser);

  // EFFECTS
  useEffect(() => {
    const appLanguageCookie = cookie[AppLanguageCookieKey];
    if (appLanguageCookie) {
      changeAppLanguage(appLanguageCookie);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookie]);

  // GOOGLE ANALYTICS
  useGaTracker();
  useGoogleTagManager();

  if (isFetching) {
    return (
      <MainTemplateLoadingWrapper>
        <LoadingPage.Lazy />
      </MainTemplateLoadingWrapper>
    );
  }

  return (
    <Fragment key={keyToForceRerender}>
      {shouldShowHeader && (
        <Header
          userRole={defaultAxiosHeaders?.userRole}
          headersWorkspaceId={defaultAxiosHeaders?.workspaceId}
          isAnotherWorkspaceSelected={isCurrentUserInAnotherWorkspace}
          shouldShowLogo={isMobile || !shouldShowNavBar}
        />
      )}

      {shouldShowNavBar && (
        <MenuNavBar
          userRole={defaultAxiosHeaders?.userRole as UserRoleEnum}
          isAnotherWorkspaceSelected={isCurrentUserInAnotherWorkspace}
          appVersionStyle={isAppV2Paths ? 'V2' : 'V1'}
        />
      )}

      {isAppV2Paths ? <APPV2GlobalStyles /> : <GlobalStyles />}
      <ThemeProvider theme={theme}>
        <Routes
          userRole={defaultAxiosHeaders?.userRole}
          workspaceId={defaultAxiosHeaders?.workspaceId}
        />
      </ThemeProvider>
    </Fragment>
  );
};

export default App;
