import { colors } from 'AppV2/styles/colors';
import { motion } from 'framer-motion/dist/framer-motion';
import styled, { CSSObject, css } from 'styled-components';

// DEFAULT
export const LoadingPageContainer = styled.div.attrs({
  className: 'loading-content flexbox-center',
})<{ styles: CSSObject }>`
  position: absolute;
  z-index: 999;
  width: 100%;
  min-height: 100vh;
  min-height: 100dvh;
  background-color: ${colors.background200};

  ${({ styles }) => styles}
`;

// MOTION ANIMATION
export const MotionDivWrapper = styled(motion.div)``;

export const MotionAnimation = {
  initial: { scale: 0.1, opacity: 0.1 },
  animate: { scale: 1, opacity: 1, animationDelay: '1.8s' },
};

// LAZY
export const LazyPageContainer = styled(LoadingPageContainer)<{
  styles: CSSObject;
}>`
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ styles }) => styles}
`;

export const BulletsWrapper = styled(motion.div)`
  display: flex;
  gap: 6px;
  justify-content: center;
  align-items: center;
`;

export const BulletLoader = styled.span<{ bulletIndex: number }>`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: inherit;

  @keyframes jumpingAnimation {
    0% {
      transform: scale(1, 1) translate3d(0, 0, 0);
    }
    50% {
      transform: scale(1.2, 0.8) translate3d(0, 15px, 0);
    }
    100% {
      transform: translate3d(0, 0, 0);
    }
  }

  ${({ bulletIndex }) => {
    if (bulletIndex === 0) {
      return css`
        background-color: #4125c2;
        animation: jumpingAnimation 0.6s 0.1s ease-in infinite;
      `;
    }
    if (bulletIndex === 1) {
      return css`
        background-color: #d71d1d;
        animation: jumpingAnimation 0.6s 0.2s ease-in infinite;
      `;
    }
    if (bulletIndex === 2) {
      return css`
        background-color: #f2bd1d;
        animation: jumpingAnimation 0.6s 0.3s ease-in infinite;
      `;
    }
  }}
`;

// BASIC
export const BasicMotionDivWrapper = styled(motion.div)`
  display: grid;
  place-items: center;
  width: 100%;
  height: 100%;
  max-height: 100%;
  max-width: 100%;

  .lottie-player {
    width: 300px;
    height: 264.63px;
    max-width: 300px;
    max-height: 264.63px;
  }
`;
